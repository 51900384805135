import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { Pagination } from 'app/shared/pagination';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const $: any;
declare const Mangler: any;

@Component({
	selector: 'app-media-library-modal',
	templateUrl: './media-library-modal.component.html',
	styleUrls: ['./media-library-modal.component.scss']
})
export class MediaLibraryModalComponent extends UIModalBaseComponent {

	@ViewChild('fileInput') fileInput;

	tabs: any[] = [
		{ id: 'upload', description: 'Upload' },
		{ id: 'asset', description: 'Asset Library' },
		{ id: 'snagg', description: 'Snagg Images' },
		{ id: 'dependency', description: 'Dependency Images' },
		{ id: 'product', description: 'Product Images' }
	];

	selectedTab;
	url = '';
	newList = [];

	list = [];
	filteredItems = {
		list: []
	};
	search = '';
	pagination = new Pagination();

	draggedOver = false;
	disabled = false;

	type = 'asset';

	multiSelect = false;
	multiSelectList = [];
	showCheckBox = false;

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(data: any) {
		this.type = data?.type || 'asset';

		this.selectedTab = 'upload';
		this.url = '';
		this.newList = [];
		this.draggedOver = false;
		this.disabled = false;

		this.modal.open();
	}

	selectTab(id) {
		this.selectedTab = id;

		if (id === 'asset' || id === 'product' || id === 'snagg' || id === 'dependency') {
			this.list = [];
			this.api.content.listUserContent(id, list => {
				this.list = list;
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}
		this.showCheckBox = !!(id === 'asset');
		this.multiSelectList = [];
		this.multiSelect = false;
	}

	selectImage(item) {
		if (this.multiSelect) {
			this.toggleImage(item);
		} else {
			this.modal.close(item);
		}
	}

	browseFile() {
		$(this.fileInput.nativeElement).val('').click();
	}

	fileDragOver(ev) {
		this.draggedOver = true;
		ev.preventDefault();
	}

	fileDrop(ev) {
		this.draggedOver = false;
		ev.preventDefault();

		// If dropped items aren't files, reject them
		const dt = ev.dataTransfer;
		let file = null;
		if (dt.items) {
			// Use DataTransferItemList interface to access the file(s)
			if (dt.items.length) file = dt.items[0].getAsFile();
		} else {
			// Use DataTransfer interface to access the file(s)
			if (dt.files.length) file = dt.files[0];
		}

		if (file) {
			this.disabled = true;
			this.uploadFile(file, list => {
				this.disabled = false;
				this.newList = this.newList.concat(list);
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error);
			});
		}
	}

	addImageURL() {
		if (this.url) {
			this.disabled = true;
			this.uploadFileByURL(this.url, list => {
				this.disabled = false;
				this.url = '';
				this.newList = this.newList.concat(list);
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error);
			});
		}
	}

	uploadFile(file, success, failure) {
		const formData = new FormData();
		formData.append('userfile', file);

		this.api.content.uploadUserContent(formData, this.type, data => {
			try {
				success(data.list);
			} catch (ex) {
				failure('No file uploaded.');
			}
		}, () => {
			failure('No file uploaded.');
		});
	}

	uploadFileByURL(url, success, failure) {
		this.api.content.uploadUserContentURL(url, this.type, data => {
			try {
				success(data.list);
			} catch (ex) {
				failure('Invalid image URL.');
			}
		}, () => {
			failure('Invalid image URL.');
		});
	}

	uploadUserContent(fileElement, success, failure) {
		if (!fileElement) {
			failure('No file uploaded.');
			return;
		}

		const fileBrowser = fileElement.nativeElement;
		if (fileBrowser.files?.[0]) {
			this.uploadFile(fileBrowser.files[0], success, failure);
		} else {
			failure('No file uploaded.');
			return;
		}
	}

	uploadImage() {
		this.disabled = true;
		this.uploadUserContent(this.fileInput, list => {
			this.disabled = false;
			this.newList = this.newList.concat(list);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error);
		});
	}

	archiveImage() {
		this.app.dialog.pop('Delete Images', 'Are you sure you want to delete ' + this.multiSelectList.length + ' images?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.content.archiveImages(this.multiSelectList, () => {
					this.app.notifications.showSuccess('Image deleted.');
					this.list = [];
					this.api.content.listUserContent(this.selectedTab, list => {
						this.list = list;
						this.multiSelectList = [];
						this.multiSelect = false;
					}, error => {
						this.app.notifications.showDanger(error.message);
					});
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	selectAllImages() {
		this.multiSelectList = Mangler.clone(this.filteredItems.list);
	}

	clearAllImages() {
		this.multiSelectList = [];
		this.multiSelect = false;
	}

	toggleImage(item) {
		if (this.isImageSelected(item)) {
			this.deselectLine(item);
			if (this.multiSelect && this.multiSelectList.length === 0) this.multiSelect = false;
		} else {
			this.selectImages(item);
			if (!this.multiSelect && this.multiSelectList.length === 1) this.multiSelect = true;
		}
	}

	selectImages(item) {
		if (!this.multiSelectList.some(value => value.id === item.id)) {
			this.multiSelectList.push(item);
		}
	}

	deselectLine(item) {
		const index = this.multiSelectList.findIndex(value => value.id === item.id);
		if (index !== -1) {
			this.multiSelectList.splice(index, 1);
		}
	}

	isImageSelected(item) {
		return this.multiSelectList.some(value => value.id === item.id);
	}

}
