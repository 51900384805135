import { Component, ViewChild, AfterViewInit, OnInit, OnDestroy, NgZone } from '@angular/core';
import { AppService } from './app.service';
import { NotificationAction, NotificationItem, NotificationsComponent } from './shared/notifications/notifications.component';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiService } from './api.service';
import { CoreNavigationComponent } from './core/core-navigation/core-navigation.component';
import { TutorialWidgetComponent } from './tutorial/tutorial-widget/tutorial-widget.component';
import { MediaLibraryModalComponent } from './media/media-library-modal/media-library-modal.component';
import { CoreDialogModalComponent } from './core/core-dialog-modal/core-dialog-modal.component';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UIModalComponent } from './ui/ui-modal.component';
import { PrimeNGConfig } from 'primeng/api';
import { polyfill } from 'seamless-scroll-polyfill';

declare const Mangler: any;
declare const $: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

	@ViewChild(NotificationsComponent) notifications: NotificationsComponent;
	@ViewChild(CoreNavigationComponent) navigation: CoreNavigationComponent;
	@ViewChild(TutorialWidgetComponent) tutorial: TutorialWidgetComponent;
	@ViewChild(MediaLibraryModalComponent) mediaLibraryModal: MediaLibraryModalComponent;
	@ViewChild(CoreDialogModalComponent) dialogModal: CoreDialogModalComponent;

	private buildVersionSubscription: Subscription;

	constructor(
		public app: AppService,
		private api: ApiService,
		private router: Router,
		private route: ActivatedRoute,
		private tooltipConfig: NgbTooltipConfig,
		private zone: NgZone,
		private primengConfig: PrimeNGConfig,
	) {
		// Make sure primeng calendar uses higher zIndex values than the modal
		this.primengConfig.zIndex.overlay = 2000;
		this.primengConfig.zIndex.modal = 2000;
		this.primengConfig.setTranslation({ firstDayOfWeek: 1 });

		// Setup default tooltip delay
		tooltipConfig.openDelay = 300;
		tooltipConfig.container = 'body';

		// @ts-ignore: private option not yet exposed for public use
		router.canceledNavigationResolution = 'computed';
	}

	ngOnInit() {
		polyfill();

		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {

				// Close all active pop ups
				$('.modal').each(function () {
					const $this = $(this);
					const component: UIModalComponent = $this.data('modalComponent');
					if (component) {
						// Component found, modal must be open. Call close method without triggering callback event.
						component.close(null, false);
					} else {
						// Modal without component, hide via Bootstrap.
						$this.modal('hide');
					}
				});

				// Remove the grey overlay
				$('.modal-backdrop').remove();

				this.refreshRouteData();
				this.api.refreshAlerts();

			}
		});

		$(document).on('focusin', e => {
			const $target = $(e.target);

			// FIXME: Workaround. Prevent Bootstrap dialog from blocking focusin for TinyMCE integration.
			if ($target.closest('.tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root').length) {
				e.stopImmediatePropagation();
			}

			// FIXME: Workaround. Prevent Bootstrap dialog from blocking @angular/cdk/clipboard module.
			if ($target.is('textarea') && '' + $target.attr('aria-hidden') === 'true' && $target.css('position') === 'fixed') {
				e.stopImmediatePropagation();
			}
		});

		// Prevent file drops to reload page when missed the drop zone
		this.zone.runOutsideAngular(() => {
			const blockDragDrop = e => {
				if (e.target?.tagName !== 'INPUT' && e.target?.tagName !== 'TEXTAREA') {
					if (e.dataTransfer) {
						e.dataTransfer.effectAllowed = 'none';
						e.dataTransfer.dropEffect = 'none';
					}
					return false;
				}
			};

			$(document).on({
				dragover: blockDragDrop,
				dragenter: blockDragDrop,
				drop: blockDragDrop
			});
		});

		this.buildVersionSubscription = this.app.onBuildVersionChange.subscribe(() => {
			this.app.notifications.showInfo('WeQuote has been updated', 'Please refresh your browser.', NotificationItem.LIFETIME_INFINITE, new NotificationAction('Refresh', () => {
				window.location.reload();
			}));
		});
	}

	ngOnDestroy() {
		if (this.buildVersionSubscription) this.buildVersionSubscription.unsubscribe();
	}

	ngAfterViewInit() {
		this.app.notifications = this.notifications;
		this.app.navigation = this.navigation;
		this.app.tutorial = this.tutorial;
		this.app.mediaLibrary = this.mediaLibraryModal;
		this.app.dialog = this.dialogModal;
	}

	private refreshRouteData() {
		const routeData = { auth: true, navigation: null };
		const routeParams = {};

		let child = this.route;
		while (child) {
			if (child.snapshot) Mangler.merge(routeData, child.snapshot.data);
			if (child.snapshot?.params) Mangler.merge(routeParams, child.snapshot.params);
			child = child.firstChild;
		}

		this.app.routeData = routeData;
		this.app.routeParams = routeParams;

		if (!this.app.routeParams.org) {
			this.app.org = null;
		}

		this.app.path = window.location.pathname;

		this.app.refreshTitle();

		const matchingModule = this.app.orgInfo?.show_introduction_list.find(item =>
			(!this.app.routeData.hasOwnProperty('id') || item.id === this.app.routeData.id)
		);

		if (matchingModule) {
			this.app.introductionRouteId = this.app.routeData.id;
		}

		// Blocking Quota Limit Users
		const quotaBalance = this.app.orgInfo?.quota_balance;
		const { navigation } = this.app.routeData;
		const { project, quote } = this.app._routeParams;
		const { blocking_project_ids, blocking_quote_ids } = this.app.orgInfo || {};

		this.app.blockQuotaLimitUsers = false;
		if (quotaBalance < 0) {
			if ((navigation === 'project' && blocking_project_ids?.includes(parseInt(project, 10))) ||
				(navigation === 'quote' && blocking_quote_ids?.includes(parseInt(quote, 10)))) {
				this.app.blockQuotaLimitUsers = true;
			}
		}
		this.app.navigation.refreshBillableTaskCount();
	}

	currentYear() {
		return (new Date()).getFullYear();
	}

	mediaLibraryModalClosed(data) {
		this.app.onMediaLibrarySelect.emit(data);
	}

}
