<form>
	<ui-modal [modalTitle]="id === 'new' ? 'New Supplier / Manufacturer' : 'Supplier / Manufacturer Details'" size="lg">
		<ng-container *ngIf="details">
			<ui-form labelWidth="10rem">
				<ui-form-control label="Entity name">
					<input class="form-control" [(ngModel)]="details.name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off" appDesktopFocus>
				</ui-form-control>
				<ui-form-control label="Type">
					<label class="custom-control custom-checkbox mt-2">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="details.is_manufacturer" [ngModelOptions]="{ standalone: true }" [disabled]="details.is_manufacturer && details.suppliers.length">
						<span class="custom-control-label">Manufacturer</span>
					</label>
					<label class="custom-control custom-checkbox">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="details.is_supplier" [ngModelOptions]="{ standalone: true }" [disabled]="details.is_supplier && details.manufacturers.length">
						<span class="custom-control-label">Supplier</span>
					</label>
				</ui-form-control>
				<hr>
				<ui-form-control label="Email address">
					<input class="form-control" [(ngModel)]="details.email_address" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Phone number">
					<input class="form-control w-60" [(ngModel)]="details.phone_number" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Mobile number">
					<input class="form-control w-60" [(ngModel)]="details.mobile_number" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Contact Name">
					<input class="form-control w-60" [(ngModel)]="details.contact_name" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<hr>
				<ui-form-control label="Address">
					<input class="form-control" [(ngModel)]="details.address_line_1" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="details.address_line_2" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control>
					<input class="form-control" [(ngModel)]="details.address_line_3" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Town">
					<input class="form-control" [(ngModel)]="details.posttown" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="County">
					<input class="form-control" [(ngModel)]="details.county" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Postcode">
					<input class="form-control w-60" [(ngModel)]="details.postcode" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ui-form-control label="Country">
					<input class="form-control" [(ngModel)]="details.country" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
				</ui-form-control>
				<ng-container *ngIf="details.for_organisation && details?.is_supplier && details.receive_po_type !== 'custom'">
					<hr>
					<ui-form-control label="Receive PO Type">
						<select class="custom-select w-auto" [(ngModel)]="details.receive_po_type" [ngModelOptions]="{ standalone: true }">
							<option [ngValue]="null"></option>
							<option value="pdf">PDF</option>
							<option value="csv">CSV</option>
						</select>
					</ui-form-control>
				</ng-container>

				<hr>
				<ui-form-control label="Info URL">
					<input class="form-control" [(ngModel)]="details.info_url" [ngModelOptions]="{ standalone: true }" autocomplete="chrome-off">
					<p class="text-secondary mt-2 mb-0"><i class="wq wq-info mr-2"></i>A web address with more information about this entity. Will be displayed as a link.</p>
				</ui-form-control>
			</ui-form>

			<ng-container *ngIf="details?.is_manufacturer">
				<hr>
				<ui-card header="Default Suppliers">
					<button type="button" class="btn btn-outline-success border-0" (click)="openNewEntity('supplier')" ui-card-header><i class="wq wq-add mr-2"></i>New supplier</button>
					<button type="button" class="btn btn-outline-secondary border-0" (click)="modalAddSupplier()" ui-card-header><i class="wq wq-add mr-2"></i>Add supplier</button>
					<button *ngIf="details.is_manufacturer && details.is_supplier && !suppliesOwnItems" type="button" class="btn btn-outline-secondary border-0" (click)="addThisEntity()" ui-card-header><i class="wq wq-add mr-2"></i>Supplies own items</button>

					<div class="alert alert-info"><i class="wq wq-info mr-2"></i>These suppliers will be added to this manufacturer's products by default. You can mark one supplier as primary.</div>

					<table *ngIf="details.suppliers.length || details.removed_suppliers.length" class="table table-hover vam">
						<thead>
							<tr>
								<th>Name</th>
								<th>Location</th>
								<th class="text-center">Primary?</th>
								<th class="text-center">Status</th>
								<th class="shrink nowrap"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of details.suppliers" [ngClass]="{ 'table-success': item.added }">
								<td><b>{{item.name}}</b> <span *ngIf="item.id === details.id" class="badge badge-medium ml-1">This entity</span></td>
								<td>{{item.posttown}} <small class="text-secondary">{{item.postcode}}</small></td>
								<td class="text-center">
									<span *ngIf="item.is_primary" class="badge badge-primary">Primary Supplier</span>
									<button *ngIf="!item.is_primary" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="setPrimarySupplier(item.id)">Set primary</button>
								</td>
								<td class="text-center">
									<span *ngIf="item.added" class="badge badge-success">New</span>
								</td>
								<td class="shrink nowrap text-right">
									<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="removeSupplier(item.id)"><i class="wq wq-delete"></i></button>
								</td>
							</tr>
							<tr *ngFor="let item of details.removed_suppliers" class="table-danger">
								<td><b>{{item.name}}</b> <span *ngIf="item.id === details.id" class="badge badge-medium ml-1">This entity</span></td>
								<td>{{item.posttown}} <small class="text-secondary">{{item.postcode}}</small></td>
								<td class="text-center"></td>
								<td class="text-center">
									<span class="badge badge-danger">Deleted</span>
								</td>
								<td class="shrink nowrap text-right">
									<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="restoreSupplier(item.id)"><i class="wq wq-undo"></i></button>
								</td>
							</tr>
						</tbody>
					</table>

					<p *ngIf="!details.suppliers.length && !details.removed_suppliers.length" class="text-secondary">No suppliers.</p>
				</ui-card>
			</ng-container>

			<ng-container *ngIf="details?.is_supplier">
				<hr>
				<ui-card header="Default Manufacturers">
					<button type="button" class="btn btn-outline-secondary border-0" (click)="modalAddManufacturer()" ui-card-header><i class="wq wq-add mr-2"></i>Add manufacturer</button>
					<button *ngIf="details.is_manufacturer && details.is_supplier && !suppliesOwnItems" type="button" class="btn btn-outline-secondary border-0" (click)="addThisEntity()" ui-card-header><i class="wq wq-add mr-2"></i>Supplies own items</button>

					<div class="alert alert-info"><i class="wq wq-info mr-2"></i>This supplier will be added to these manufacturers' products by default. Mark any lines as primary if you want this supplier to be the primary supplier for that manufacturer.</div>

					<table *ngIf="details.manufacturers.length || details.removed_manufacturers.length" class="table table-hover vam">
						<thead>
							<tr>
								<th>Name</th>
								<th>Location</th>
								<th class="text-center">Primary?</th>
								<th class="text-center">Status</th>
								<th class="shrink nowrap"></th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of details.manufacturers" [ngClass]="{ 'table-success': item.added }">
								<td><b>{{item.name}}</b> <span *ngIf="item.id === details.id" class="badge badge-medium ml-1">This entity</span></td>
								<td>{{item.posttown}} <small class="text-secondary">{{item.postcode}}</small></td>

								<td *ngIf="item.id === details.id" class="text-center">
									<span *ngIf="item.is_primary" class="badge badge-primary">Primary Supplier</span>
									<button *ngIf="!item.is_primary" type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="setPrimarySupplier(item.id)">Set primary</button>
								</td>
								<td *ngIf="item.id !== details.id" class="text-center">
									<label class="custom-control custom-checkbox m-0">
										<input type="checkbox" class="custom-control-input" [(ngModel)]="item.is_primary" [ngModelOptions]="{ standalone: true }">
										<span class="custom-control-label"></span>
									</label>
								</td>

								<td class="text-center">
									<span *ngIf="item.added" class="badge badge-success">New</span>
								</td>
								<td class="shrink nowrap text-right">
									<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="removeManufacturer(item.id)"><i class="wq wq-delete"></i></button>
								</td>
							</tr>
							<tr *ngFor="let item of details.removed_manufacturers" class="table-danger">
								<td><b>{{item.name}}</b> <span *ngIf="item.id === details.id" class="badge badge-medium ml-1">This entity</span></td>
								<td>{{item.posttown}} <small class="text-secondary">{{item.postcode}}</small></td>

								<td class="text-center"></td>

								<td class="text-center">
									<span class="badge badge-danger">Deleted</span>
								</td>
								<td class="shrink nowrap text-right">
									<button type="button" class="btn btn-outline-secondary btn-sm border-0 px-1" (click)="restoreManufacturer(item.id)"><i class="wq wq-undo"></i></button>
								</td>
							</tr>
						</tbody>
					</table>

					<p *ngIf="!details.manufacturers.length && !details.removed_manufacturers.length" class="text-secondary">No manufacturers.</p>
				</ui-card>
			</ng-container>

			<ng-container *ngIf="details && showUpdateOptions" weight-0>
				<hr>
				<ui-card header="Update Options" class="border-warning border-3">
					<label *ngIf="hasNew" class="custom-control custom-control-success custom-checkbox">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="details.update_new" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Add new suppliers to existing products</span>
					</label>
					<label *ngIf="hasRemoved" class="custom-control custom-control-danger custom-checkbox">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="details.update_remove" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Remove suppliers from existing products</span>
					</label>
					<label *ngIf="hasRecords" class="custom-control custom-control-warning custom-checkbox">
						<input type="checkbox" class="custom-control-input" [(ngModel)]="details.update_primary" [ngModelOptions]="{ standalone: true }">
						<span class="custom-control-label">Update primary supplier on existing products</span>
					</label>
				</ui-card>
			</ng-container>

		</ng-container>

		<button *ngIf="!details?.archived && id !== 'new' && !manufacturerProductCount && !supplierProductCount" type="button" class="btn btn-danger" (click)="archive()" [disabled]="disabled" ui-modal-footer>Archive Entity</button>
		<button *ngIf="details?.archived" type="button" class="btn btn-danger" (click)="unarchive()" [disabled]="disabled" ui-modal-footer>Restore Entity</button>
		<div class="flex-weight-1" ui-modal-footer></div>
		<button type="button" class="btn btn-medium" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="save()" [disabled]="disabled" ui-modal-footer>Save</button>
	</ui-modal>
</form>

<modal-select-entity (close)="entitySelectModalClosed($event)"></modal-select-entity>
<ng-template (close)="newEntityClosed($event)" modalEditEntity></ng-template>
