<form>
	<ui-modal modalTitle="Edit Content" size="xl">
		<ui-alert color="info" flag="contentMenuTip">To access the system context menu within the editor (e.g. <i>Copy</i>, <i>Paste</i>, <i>Spell Check</i>), press <b>Ctrl + Right Click</b>.</ui-alert>

		<div *ngIf="showEditor" style="max-width: 1024px; margin: auto;">
			<editor [init]="tinyInit" [(ngModel)]="content" [ngModelOptions]="{ standalone: true }"></editor>
		</div>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
		<button type="submit" class="btn btn-primary" (click)="saveButton()" ui-modal-footer>Save</button>
	</ui-modal>
</form>
