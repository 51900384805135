import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-select-snag',
	templateUrl: './modal-select-snag.component.html'
})
export class ModalSelectSnagComponent extends UIModalBaseComponent {

	projectId;
	list: any[] = [];
	search = '';

	constructor(
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.projectId = mdata.project_id;

		this.list = null;
		this.api.project.listSnags(this.projectId, data => {
			this.list = Mangler.filter(data, { is_quoted: 0 });
		});

		this.modal.open();
	}

	selectItem(item) {
		this.modal.close(item);
	}

}
