import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreHeaderComponent } from './core-header/core-header.component';
import { CoreNavigationComponent } from './core-navigation/core-navigation.component';
import { UIModule } from 'app/ui/ui.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { CoreFeedbackModalComponent } from './core-feedback-modal/core-feedback-modal.component';
import { CoreQuoteHeaderComponent } from './core-quote-header/core-quote-header.component';
import { CoreKnowledgebaseModalComponent } from './core-knowledgebase-modal/core-knowledgebase-modal.component';
import { CoreQuoteForwardModalComponent } from './core-quote-forward-modal/core-quote-forward-modal.component';
import { TutorialModule } from 'app/tutorial/tutorial.module';
import { CoreDialogModalComponent } from './core-dialog-modal/core-dialog-modal.component';
import { AlertModule } from 'app/alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { ModalModule } from 'app/modal/modal.module';
import { CoreQuoteReviewResultModalComponent } from './core-quote-review-result-modal/core-quote-review-result-modal.component';
import { CoreIntroductionComponent } from './core-introduction/core-introduction.component';
import { CoreAlertComponent } from './core-alert/core-alert.component';
import { CoreProjectHeaderComponent } from './core-project-header/core-project-header.component';
import { CoreBlockQuoteLimitUsersComponent } from './core-block-quote-limit-users/core-block-quote-limit-users.component';

@NgModule({
	declarations: [
		CoreHeaderComponent,
		CoreNavigationComponent,
		CoreFeedbackModalComponent,
		CoreQuoteHeaderComponent,
		CoreKnowledgebaseModalComponent,
		CoreQuoteForwardModalComponent,
		CoreDialogModalComponent,
		CoreQuoteReviewResultModalComponent,
		CoreIntroductionComponent,
		CoreAlertComponent,
		CoreProjectHeaderComponent,
		CoreBlockQuoteLimitUsersComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		UIModule,
		SharedModule,
		TutorialModule,
		AlertModule,
		NgbModule,
		CalendarModule,
		ModalModule
	],
	exports: [
		CoreHeaderComponent,
		CoreNavigationComponent,
		CoreDialogModalComponent,
		CoreIntroductionComponent,
		CoreAlertComponent,
		CoreBlockQuoteLimitUsersComponent
	]
})
export class CoreModule { }
