<ui-modal modalTitle="Select Snag" size="xl">

	<div class="input-group mb-2">
		<span class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></span>
		<input type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
	</div>

	<div style="height: 600px;" class="scrollable mb-2">

		<p *ngIf="!list?.length" class="text-secondary">No snags found.</p>

		<table class="table table-hover vam" *ngIf="list?.length">
			<thead>
				<tr>
					<th class="sticky text-center shrink">Image</th>
					<th class="sticky">Description</th>
					<th class="sticky text-center">Estimated Price</th>
					<th class="sticky text-center">Estimated Hours</th>
					<th class="sticky text-center">Created By</th>
					<th class="sticky text-center">Status</th>
					<th class="sticky text-right">#</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let snag of list | keywords:search:['short_description', 'long_description']" class="selectable" (click)="selectItem(snag)">
					<td class="shrink">
						<div *ngIf="snag.image_url" class="product-image" [style.backgroundImage]="'url(\'' + snag.image_url + '\')' | safeStyle"></div>
					</td>
					<td class="text-bold">{{snag.short_description}}</td>
					<td class="text-center">{{snag.estimated_value | decimal:2:4:true:snag.currency_id}}</td>
					<td class="text-center">{{snag.hours}}</td>
					<td class="text-center">
						{{snag.user_created}}
						<div class="text-secondary">{{snag.created_datetime | mySQLDateToISO | localizedDate:'shortDate'}} {{snag.created_datetime | mySQLDateToISO | localizedDate:'shortTime'}}</div>
					</td>
					<td class="text-center">
						<span *ngIf="!snag.is_quoted" class="badge badge-secondary">Not Quoted</span>
						<span *ngIf="snag.is_quoted" class="badge badge-success">Quoted</span>
					</td>
					<td class="text-secondary text-tiny text-right">{{snag.id}}</td>
				</tr>
			</tbody>
		</table>
	</div>

	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>

</ui-modal>
