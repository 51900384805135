import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';

@Component({
	selector: 'app-core-project-header',
	templateUrl: './core-project-header.component.html'
})
export class CoreProjectHeaderComponent implements OnInit {

	@Input() projectId;

	id;
	data;
	refreshTimer;

	disabled = false;

	constructor(
		public app: AppService,
		private api: ApiService
	) { }

	ngOnInit(): void {
		this.refresh();
	}

	refresh() {
		this.api.project.invoicing({ id:this.projectId }, data => {
			this.data = data;
		}, () => {
			this.disabled = false;
		});
	}

	projectStageList() {
		return ['active', 'completed', 'cancelled'];
	}

	projectStageColor(stage) {
		switch (stage) {
			case 'cancelled': return 'danger';
			case 'active': return 'info';
			case 'completed': return 'success';
		}
		return 'secondary';
	}

	projectStageDescription(stage) {
		switch (stage) {
			case 'cancelled': return 'Cancelled';
			case 'active': return 'Active';
			case 'completed': return 'Completed';
		}
		return '';
	}

	changeProjectStage(s) {
		const stage = s;

		if (stage === 'active') {
			this.api.project.changeStatus(this.projectId, stage, () => {
				this.app.notifications.showSuccess('Project status updated.');
				this.refresh();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		} else {
			this.api.project.invoicing({ id:this.projectId }, data => {
				// Assuming data is your JSON object
				const invoices = data.invoices;
				const quotes = data.quotes;

				// Count draft and outstanding invoices
				const draftInvoicesCount = invoices.filter(invoice => invoice.status === 'draft').length;
				const outstandingInvoicesCount = invoices.filter(invoice => invoice.status === 'outstanding').length;

				// Count quotes not in 'accepted', 'complete', or 'cancelled' stage
				const quotesCount = quotes.filter(quote => {
					const stages = ['accepted', 'complete', 'cancelled'];
					return !stages.includes(quote.stage);
				}).length;

				let content = '';
				if ((draftInvoicesCount + quotesCount + outstandingInvoicesCount) > 0) content += '<p>Invoices and Quote Details : </p>';
				if (draftInvoicesCount > 0) content += '<li>' + draftInvoicesCount + ' draft invoices</li>';
				if (outstandingInvoicesCount > 0) content += '<li>' + outstandingInvoicesCount + ' outstanding invoices</li>';
				if (quotesCount > 0) content += '<li>' + quotesCount + ' quotes that are not accepted or cancelled or completed</li>';
				content += '<br> <p class="text-bold">Do you want to change the status of the project?</p>';

				this.app.dialog.popHtml('Change Project Status', content, ['Cancel', '*Change Project Status'], btn => {
					if (btn === 1) {
						this.api.project.changeStatus(this.projectId, stage, () => {
							this.app.notifications.showSuccess('Project status updated.');
							this.refresh();
							this.app.projectStatusChange.emit(stage);
						}, error => {
							this.app.notifications.showDanger(error.message);
						});
					}
				});
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}
	}

}
