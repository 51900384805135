import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditContentComponent } from '../modal-edit-content/modal-edit-content.component';

@Component({
	selector: 'modal-edit-category-group',
	templateUrl: './modal-edit-category-group.component.html',
	styleUrls: ['./modal-edit-category-group.component.scss']
})
export class ModalEditCategoryGroupComponent extends UIModalBaseComponent {

	@ViewChild(ModalEditContentComponent) editContentModal: ModalEditContentComponent;

	id;
	quoteId;
	data;
	headerList = [];
	disabled = false;

	canDelete = false;
	canArchive = false;
	canUnarchive = false;

	title = '';

	tabDetails = { id: 'details', description: 'Details' };
	tabSubsystems = { id: 'subsystems', description: 'Reorder' };

	tabs: any[] = [];
	selectedTab = 'details';

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id || 'new';
		this.quoteId = mdata.quoteId || null;
		this.data = null;
		this.canDelete = false;
		this.canArchive = false;
		this.canUnarchive = false;

		const success = data => {
			this.data = data;

			this.title = this.data.record.id === 'new' ? 'New System' : this.data.record.description;

			// Check if record can be deleted
			if (this.quoteId) {
				this.canDelete = false;
				this.canArchive = false;
				this.canUnarchive = false;
			} else {
				if (this.data.record.archived) {
					this.canUnarchive = true;
				} else if (this.id !== 'new') {
					this.api.category.canDeleteGroup(this.id, deleteInfo => {
						this.canDelete = deleteInfo.can_delete;
						this.canArchive = deleteInfo.can_archive;
					});
				}
			}

			this.selectedTab = 'details';
			this.tabs = [];
			// Only allow them to reorder subsystems if form wasn't called in the context of a quote and there are subsystems in the system
			if (!this.quoteId && this.data.categories.length) {
				this.tabs.push(this.tabDetails);
				this.tabs.push(this.tabSubsystems);
			}

			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		this.api.template.listHeaders(list => {
			this.headerList = list;
			if (this.id === 'new') {
				this.api.category.newGroup(success, fail);
			} else {
				this.api.category.getGroup(this.id, this.quoteId, success, fail);
			}
		}, fail);
	}

	deleteCategoryGroup() {
		if (!this.canDelete) return;

		this.app.dialog.pop('Delete System', 'Are you sure you want to delete this system?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.category.deleteGroup(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('System deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	archiveCategoryGroup() {
		if (!this.canArchive) return;

		this.app.dialog.pop('Delete System', 'Are you sure you want to delete this system?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.category.archiveGroup(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('System deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	unarchiveCategoryGroup() {
		if (!this.canUnarchive) return;

		this.disabled = true;
		this.api.category.unarchiveGroup(this.id, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('System restored.');
			this.modal.close('restored');
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	save() {
		this.disabled = true;
		this.api.category.saveGroup(this.data, savedId => {
			this.disabled = false;
			this.modal.close(savedId);
			if (this.data.record.id === 'new') {
				this.app.notifications.showSuccess('System created.');
			} else {
				this.app.notifications.showSuccess('System updated.');
			}
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	editContentModalClosed(data) {
		if (data) {
			this.data.record.heading_content = data.content;
		}
	}

	categoryDrop(event) {
		// Update data model
		const previousIndex = event.previousIndex;
		const currentIndex = event.currentIndex;

		if (previousIndex === currentIndex) return; // No change

		const item = this.data.categories.splice(previousIndex, 1)[0];
		this.data.categories.splice(currentIndex, 0, item);
	}

	removeImage(attr) {
		this.data.record[attr + '_id'] = null;
		this.data.record[attr + '_url'] = null;
	}

	openMediaLibrary(attr) {
		const sub = this.app.onMediaLibrarySelect.subscribe(data => {
			sub.unsubscribe();
			if (data) {
				this.data.record[attr + '_id'] = data.id;
				this.data.record[attr + '_url'] = data.url;
			}
		});

		this.app.mediaLibrary.open({ type: 'asset' });
	}

	restoreImage(attr) {
		this.data.record[attr + '_id'] = this.data.original[attr + '_id'];
		this.data.record[attr + '_url'] = this.data.original[attr + '_url'];
	}

}
