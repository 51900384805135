declare const $: any;

export class Util {

	private static objectMap = new WeakMap();
	private static lastObjectId = 0;

	static scrollIntoView(className) {
		setTimeout(() => {
			$('.' + className).scrollintoview({ duration: 100 });
		}, 0);
	}

	static objectId(obj) {
		if (this.objectMap.has(obj)) {
			return this.objectMap.get(obj);
		} else {
			const id = ++this.lastObjectId;
			this.objectMap.set(obj, id);
			return id;
		}
	}

}
