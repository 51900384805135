import { Component } from '@angular/core';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-content',
	templateUrl: './modal-edit-content.component.html'
})
export class ModalEditContentComponent extends UIModalBaseComponent {

	content;
	showEditor = false;
	tinyInit;

	constructor(
		public app: AppService
	) {
		super();

		this.tinyInit = Mangler.clone(this.app.tinyInit);
		this.tinyInit.min_height = 400;
		this.tinyInit.max_height = 700;
		this.tinyInit.content_css = this.app.tinyContentFullWidth;
	}

	open(content: any) {
		this.content = content;
		this.showEditor = true;

		this.modal.easyClose = false;
		this.modal.open();

		const sub = this.modal.onClose.subscribe(() => {
			this.showEditor = false;
			sub.unsubscribe();
		});
	}

	saveButton() {
		this.modal.close({
			content: this.content
		});
	}

}
