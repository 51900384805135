import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root'
})
export class QuoteGuardService implements CanActivate, CanLoad {
	private quote_id: string | null = null;
	private module: string | null = null;
	private path: string | null = null;

	constructor(
		private app: AppService,
		private api: ApiService,
		private router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return new Promise((resolve) => {
			this.app.org = route.params['org'] || null;
			this.quote_id = route.params['quote'] || null;
			this.module = route.data['module'] || 'quote';
			this.path = route.data['path'] || 'summary';

			if (this.quote_id) {
				this.api.quote.isSalesOrder(this.quote_id, (data: boolean) => {
					if (this.module === 'quote' && data) {
						resolve(this.router.parseUrl(`/${this.app.org}/sales-order/${this.quote_id}/${this.path}`));
					} else if (this.module === 'sales-order' && !data) {
						resolve(this.router.parseUrl(`/${this.app.org}/quote/${this.quote_id}/${this.path}`));
					} else {
						resolve(true);
					}
				}, (error) => {
					this.app.notifications.showDanger(error.message);
					resolve(false);
				});
			} else {
				resolve(true);
			}
		});
	}

	canLoad(_route: Route, _segments: UrlSegment[]): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
		this.api.general.ping();
		return true;
	}
}
