import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { QuoteService } from 'app/quote/quote.service';
import { MySQLDateToISOPipe } from 'app/shared/mysql-date-to-iso.pipe';
import { Pagination } from 'app/shared/pagination';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

declare const Mangler: any;

@Component({
	selector: 'modal-select-quote',
	templateUrl: './modal-select-quote.component.html'
})
export class ModalSelectQuoteComponent extends UIModalBaseComponent {

	list: any[] = null;
	filters = {};
	search = '';
	pagination = new Pagination();

	multiSelect = false;
	multiSelectList = [];

	modes = [
		{ id: 'quotes', description: 'Quotes', icon: 'wq wq-handshake' }
	];
	mode = 'quotes';
	type = 'quote';
	title = 'Select Quotes';
	constructor(
		public app: AppService,
		private api: ApiService,
		private datePipe: DatePipe,
		public service: QuoteService
	) {
		super();
	}

	open(data: any = null) {
		data = data || null;

		this.search = '';
		this.list = null;
		this.mode = 'quotes';
		this.type = (data.sales_order && data.sales_order == 1 ? 'sales_order' : 'quote');
		this.multiSelect = !!(data.multi_select);
		this.multiSelectList = [];
		if(this.type == 'sales_order'){
			this.title = 'Select Sales Order';
			this.modes = [
				{ id: 'quotes', description: 'Sales Order', icon: 'wq wq-handshake' }
			];
		}
		else{
			this.title = 'Select Quotes';
			this.modes = [
				{ id: 'quotes', description: 'Quotes', icon: 'wq wq-handshake' }
			];
		}
		delete data.multi_select;
		this.filters = data;

		this.refresh();

		this.modal.open();
	}

	refresh() {
		this.api.quote.list(this.filters, data => {
			this.list = data.list;
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	selectItem(item) {
		if (!item) return;

		if (this.multiSelect) {
			const found = Mangler.findOne(this.multiSelectList, { id: item.id });
			if (found) {
				const i = this.multiSelectList.indexOf(found);
				if (i !== -1) this.multiSelectList.splice(i, 1);
			} else {
				this.multiSelectList.push(item);
			}
			this.multiSelectList = this.multiSelectList.slice();
		} else {
			this.modal.close(item);
		}
	}

	selectMode(mode) {
		this.mode = mode;

		if (mode === 'selected') return;

		this.list = null;
		this.refresh();
	}

	addQuotesButton() {
		if (this.multiSelectList.length) {
			this.modal.close(this.multiSelectList);
		}
	}

	isItemSelected(item) {
		return !!Mangler.findOne(this.multiSelectList, { id: item.id });
	}

	stageList() {
		return ['all', 'in_progress', 'in_review', 'passed_review', 'sent', 'accepted'];
	}

	stageColor(stage, selected = true, defaultColor = 'secondary') {
		if (!selected) return defaultColor;

		switch (stage) {
			case 'all': return 'primary';
			case 'active': return 'primary';
			case 'archived': return 'primary';
			case 'cancelled': return 'danger';
			case 'in_progress': return 'secondary';
			case 'in_review': return 'warning';
			case 'passed_review': return 'info';
			case 'sent': return 'purple';
			case 'accepted': return 'success';
			case 'complete': return 'success';
		}
		return 'secondary';
	}

	stageDescription(stage) {
		switch (stage) {
			case 'all': return 'All';
			case 'active': return 'Active';
			case 'archived': return 'Archived';
			case 'cancelled': return 'Cancelled';
			case 'in_progress': return 'In Progress';
			case 'in_review': return 'In Review';
			case 'passed_review': return 'Passed Review';
			case 'sent': return 'Sent';
			case 'accepted': return 'Accepted';
			case 'complete': return 'Complete';
		}
		return '';
	}

	formattedSQLDate(date) {
		const d = MySQLDateToISOPipe.stringToDate(date);
		return `Sent: ${this.datePipe.transform(d, 'shortDate')}`;
	}

}
