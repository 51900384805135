import { Component } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';

@Component({
	selector: 'modal-activity-log',
	styleUrls: ['./modal-activity-log.component.scss'],
	templateUrl: './modal-activity-log.component.html'
})
export class ModalActivityLogComponent extends UIModalBaseComponent {

	id;
	type;
	activityLog;
	documents = [];

	note = '';
	docName = '';
	docUrl = '';

	editNote = '';
	editing = null;

	notesTab = { id: 'notes', description: 'Notes', count: 0 };
	documentsTab = { id: 'documents', description: 'Documents', count: 0 };

	selectedTab = 'notes';
	tabs = [this.notesTab, this.documentsTab];

	constructor(
		private api: ApiService,
		private app: AppService
	) {
		super();
	}

	open(data: any = null) {
		this.id = data['id'];
		this.type = data['type'];
		this.selectedTab = 'notes';
		this.notesTab.count = 0;
		this.documentsTab.count = 0;
		this.documents = [];

		if (this.type === 'task') this.tabs = [this.notesTab];
		this.note = '';
		this.docName = '';
		this.docUrl = '';

		this.activityLog = null;
		this.reload(true);
	}

	reload(open = false) {
		this.api.activity.get(this.id, this.type, (activity) => {
			this.editing = null;
			this.activityLog = [];
			this.documents = [];
			this.notesTab.count = 0;
			this.documentsTab.count = 0;

			activity.activities.forEach(item => {
				if (item.activity_type === 'document') {
					this.documentsTab.count += 1;
					this.documents.push(item);
				} else if (item.activity_type === 'note')  {
					this.notesTab.count += 1;
					this.activityLog.push(item);
				}

				if (item.json_data) {
					try {
						item.json_data = JSON.parse(item.json_data);
					} catch (ex) {
						item.json_data = null;
					}
				}
			});

			if (open) this.modal.open();

			if (this.type === 'quote') this.app.navigation.refreshQuoteNotes();
			if (this.type === 'project') this.app.navigation.refreshProjectNotes();
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	newActivity() {
		if (this.note) {
			this.api.activity.create({ source: this.id, type: this.type, note: this.note }, () => {
				this.note = '';
				this.reload();
			}, error => {
				this.app.notifications.showDanger(error.message);
			});
		}
	}

	newDocument() {
		if (!this.docName) {
			this.app.notifications.showDanger('Please enter document name.');
			return;
		}

		if (!this.docUrl) {
			this.app.notifications.showDanger('Please enter document URL.');
			return;
		}

		if (!this.docUrl.includes('://')) {
			this.docUrl = 'https://' + this.docUrl;
		}

		this.api.activity.create({ source: this.id, type: this.type, note: this.docName, activity_type: 'document', json_data: { url: this.docUrl, display_order: 9999 } }, () => {
			this.docName = '';
			this.docUrl = '';
			this.reload();
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	editMode(i) {
		this.editNote = this.activityLog[i].note;
		this.editing = i;
	}

	save() {
		if (!this.editing === null) return;

		const a = this.activityLog[this.editing];
		this.api.activity.update({
			id: a.id,
			note: this.editNote
		}, () => {
			this.reload();
			this.editing = null;
			this.note = '';
		}, error => {
			this.app.notifications.showDanger(error.message);
		});
	}

	confirmDelete(i) {
		this.app.dialog.pop('Delete Note', 'Are you sure you want to delete "' + this.activityLog[i].note + '"?', ['Cancel', '*Delete'], (button) => {
			if (button === 1) {
				this.api.activity.delete({ id: this.activityLog[i].id }, () => {
					this.reload();
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	deleteDocument(item) {
		this.app.dialog.pop('Delete Document', 'Are you sure you want to delete "' + item.note + '"?', ['Cancel', '*Delete'], (button) => {
			if (button === 1) {
				this.api.activity.delete({ id: item.id }, () => {
					this.reload();
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

}
