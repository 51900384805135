<form>
	<ui-modal [modalTitle]="modalTitle">
		<p>{{subTitle}}</p>

		<ui-form labelWidth="10rem">
			<ui-form-control label="Accepted date">
				<p-calendar [(ngModel)]="record.accepted_date" [ngModelOptions]="{ standalone: true }" [dateFormat]="app.calendarDateFormat" inputStyleClass="form-control w-7r" appendTo="body"></p-calendar>
			</ui-form-control>
		</ui-form>

		<ng-container *ngIf="quota && quota.cost">
			<hr>
			<p class="text-center text-secondary text-uppercase text-small text-bold">Quotes value <span [ngClass]="{ 'text-success': quota.new_spent <= quota.quota, 'text-danger': quota.new_spent > quota.quota }">{{quota.new_spent | decimal:0:0:true:'GBP'}}</span> / {{quota.quota | decimal:0:0:true:'GBP'}}</p>
			<p *ngIf="quota.new_spent > quota.quota" class="text-center text-secondary text-small">This will take you over your accepted quote value limit. You are able to accept this quote but must upgrade to accept further quotes.</p>
			<p *ngIf="quota.new_quota_date" class="text-center text-secondary text-bold text-small">New quota available {{quota.new_quota_date | mySQLDateToISO | localizedDate:'shortDate'}}</p>
		</ng-container>

		<button type="button" class="btn btn-secondary" (click)="modal.close()" [disabled]="disabled" ui-modal-footer>Cancel</button>
		<button type="submit" *ngIf="this.app.routeData.module === 'quote'" class="btn btn-success" (click)="markAsAcceptedButton()" [disabled]="disabled" ui-modal-footer>Mark As Accepted</button>
		<button type="submit" *ngIf="this.app.routeData.module === 'sales-order'" class="btn btn-success" (click)="markAsCompletedButton()" [disabled]="disabled" ui-modal-footer>Mark As Completed</button>
	</ui-modal>
</form>
